import Card from "./Card";
import postcode from "../assets/Postcode-app.png";
import "./Projects.css";

const Projects = () => {
  return (
    <div className="projects">
      <h1>WORK</h1>
      <div className="projectdetails">
        <h3>PERSONAL PROJECTS</h3>
        <h2>My personal projects</h2>
        <div className="cardgroup">
          <Card image={postcode}>
            <h3>{"UK postcode searching app"}</h3>
            <p>{"Skills: React, Js, CSS, REST"}</p>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Projects;
