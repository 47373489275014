import React from "react";
import {
  FaGithub,
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaReact,
  FaHeart,
} from "react-icons/fa";
import "./Footer.css";

const Footer = (props) => {
  return (
    <div className="footer">
      <ul>
        <li>
          <a href={props.data.social.github} target="_blank" rel="noreferrer">
            <FaGithub className="skilliconsimage-footer" />
          </a>
        </li>
        <li>
          <a href={props.data.social.facebook} target="_blank" rel="noreferrer">
            <FaFacebook className="skilliconsimage-footer" />
          </a>
        </li>
        <li>
          <a
            href={props.data.social.instagram}
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram className="skilliconsimage-footer" />
          </a>
        </li>
        <li>
          <a href={props.data.social.linkedin} target="_blank" rel="noreferrer">
            <FaLinkedinIn className="skilliconsimage-footer" />
          </a>
        </li>
      </ul>
      <div>
        Designed by Likhan Sarkar using <FaReact /> and with <FaHeart />.
        <br></br>
        <br></br>
        Please visit my <FaGithub /> to support.
      </div>
    </div>
  );
};

export default Footer;
