import "./AboutMe.css";

const AboutMe = () => {
  return (
    <section className="aboutme">
      <div className="image1">
        <p className="imageCaption">Coffee is essential</p>
      </div>
      <div className="image2">
        {" "}
        <p className="imageCaption">I like beaches</p>
      </div>
      <div className="image3">
        {" "}
        <p className="imageCaption">I work out of London</p>
      </div>
      <div className="image4">
        {" "}
        <p className="imageCaption">I like to travel</p>
      </div>
      <div className="image5">
        {" "}
        <p className="imageCaption">Originally from Calcutta</p>
      </div>
      <div className="image6">
        {" "}
        <p className="imageCaption">Proud ally</p>
      </div>
      <div className="image7">
        {" "}
        <p className="imageCaption">I love playing valorant</p>
      </div>
    </section>
  );
};

export default AboutMe;
