import "./NameSlider.css";

const NameSlider = () => {
  return (
    <div className="sliderDiv">
      <h2>HELLO! I'M</h2>
      <h1>LIKHAN SARKAR</h1>
      <h2>I'M A SOFTWARE ENGINEER</h2>
    </div>
  );
};

export default NameSlider;
