import "./About.css";

const About = (props) => {
  return (
    <div className="about">
      <h1>ABOUT</h1>
      <div className="details">
        <h2>{props.data.about.helloText}</h2>
        <h3>{props.data.about.about}</h3>
        <a href={props.data.about.resume} target="_blank" rel="noreferrer">
          {props.data.about.checkoutResume}
        </a>
        <h5>{props.data.about.contactMe}</h5>
        <h4 className="eph">
          Email:{" "}
          <a href={`mailto:${props.data.about.email}`}>
            {props.data.about.email}
          </a>
        </h4>
        <h4 className="eph">
          Phone: <p>{props.data.about.phoneNo}</p>
        </h4>
      </div>
      <div className="image"></div>
    </div>
  );
};

export default About;
