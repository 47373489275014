import {
  FaGithub,
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import Modal from "./Modal.js";
import "./NavModal.css";

const NavModal = (props) => {
  return (
    <Modal onClose={props.onClose}>
      <GrClose onClick={props.onClose} className="closeIcon" />
      <div className="navModal">
        <h5>Contact Me Here!</h5>
        <h4> {"Email:"}</h4>
        <a href={`mailto:${props.data.about.email}`}>
          {props.data.about.email}
        </a>
        <p> {`Phone: ${props.data.about.phoneNo}`}</p>
      </div>
      <div className="navfooter">
        <ul>
          <li>
            <a href={props.data.social.github} target="_blank" rel="noreferrer">
              <FaGithub className="skilliconsimage-nav" />
            </a>
          </li>
          <li>
            <a
              href={props.data.social.facebook}
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebook className="skilliconsimage-nav" />
            </a>
          </li>
          <li>
            <a
              href={props.data.social.instagram}
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram className="skilliconsimage-nav" />
            </a>
          </li>
          <li>
            <a
              href={props.data.social.linkedin}
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedinIn className="skilliconsimage-nav" />
            </a>
          </li>
        </ul>
      </div>
    </Modal>
  );
};

export default NavModal;
