import "./Card.css";

const Card = (props) => {
  return (
    <div className="card-container">
      <img className="card-image" src={props.image} alt="postcode" />
      <div className="card-content">{props.children}</div>
    </div>
  );
};

export default Card;
