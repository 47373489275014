import React from "react";
import { getDatabase, ref, get } from "firebase/database";
import { BiUpArrow } from "react-icons/bi";
import { app } from "./config/firebaseConfig";
import About from "./components/About";
import AboutMe from "./components/AboutMe";
import Footer from "./components/Footer";
import Header from "./components/Header";
import NameSlider from "./components/NameSlider";
import NavModal from "./components/NavModal";
import Projects from "./components/Projects";
import Services from "./components/Services";
import "./App.css";

function App() {
  const [showNav, setShowNav] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState({});

  React.useEffect(() => {
    const fetchdata = async () => {
      const db = getDatabase(app);
      const dbRef = ref(db);
      const snapShot = await get(dbRef);
      if (snapShot.exists) {
        return snapShot.val();
      }
      return 0;
    };

    fetchdata().then((response) => {
      if (response) {
        setLoading(false);
        setData(response);
      }
    });
  }, []);

  const showNavHandler = () => {
    setShowNav(true);
  };

  const hideNavHandler = () => {
    setShowNav(false);
  };

  const handleScroll = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {loading && <div className="loading">{"Loading...."}</div>}
      {!loading && (
        <div className="appContainer">
          {showNav && <NavModal onClose={hideNavHandler} data={data} />}
          <div className="app">
            <Header onShow={showNavHandler} />
            <NameSlider />
            <About data={data} />
            <AboutMe />
            <Services />
            <Projects />
            <div className="buttonContainer">
              <button className="button" onClick={handleScroll}>
                <BiUpArrow className="buttonimage" />
              </button>
            </div>
            <Footer data={data} />
          </div>
        </div>
      )}
    </>
  );
}

export default App;
