import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyBjuB08vNRPJgo_GhXV08CMmytAK5CkeBo",
  authDomain: "likhan-portfolio.firebaseapp.com",
  databaseURL:
    "https://likhan-portfolio-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "likhan-portfolio",
  storageBucket: "likhan-portfolio.appspot.com",
  messagingSenderId: "193105531400",
  appId: "1:193105531400:web:444203dd0fecb1fbe9f7bd",
  measurementId: "G-SKR0214ZTH",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
